<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center " md="6" lg="6" xl="6">
                        <div class="font-weight-bold body-1">
                            Detalles de materia prima
                        </div>
                    </v-col>
                </v-row> 
                <v-row justify="center">
                    <v-col cols="12" md="6" lg="6" xl="6" >

                    <details-item :detailsItems="items" data-cy="data-details-items"></details-item>


                    </v-col>
                    
                </v-row>

                <v-row justify="center">
                    <v-col cols="12" md="6" lg="6" xl="6" class="pt-3 pb-0 font-weight-bold body-2 mb-0" >
                        <p class="mb-0 pb-0">Variantes</p>
                    </v-col>
                </v-row>

                <v-row justify="center" >
                    <v-col class="py-3" cols="12" md="6" lg="6" xl="6">
                        <table-items data-cy="table-items" :props="tableProps"  :items="tableProps.items">
                            
                           

                           
                            
                        </table-items>
                        
                    </v-col>
                    
                </v-row>

               
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" lg="6" xl="6" class="d-flex justify-end">
                    
					<primary-button data-cy="cancel-btn" :props="cancelBtnData"></primary-button>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>
import DetailsItem from '../../../components/DetailsItem.vue';
import Utils from '../../../helpers/Utils';
export default {
    components: {
        DetailsItem,
        
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
           
            items: [
               
                
            ],

            cancelBtnData: {
                text: "Regresar",
                icon: "",
                to: "Commodities",
                block:false,
                click: () => {}
            },
            
            tableProps: {
				headers: [
                     {
						text: 'Tamaño',
						align: 'left break-words',
						value: 'size',
                        width:"50%",
						class: 'table-b-border black--text',
                        sortable: false,
					},
					
                    {
						text: 'Color',
						align: 'center',
						value: 'color',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},
                    {
						text: 'Precio',
						align: 'center',
						value: 'price',
						sortable: false,
                        // width: "15%",
						class: 'table-b-border black--text'
					},

					
				],
				items: [],
				search: '',
				loading: false
			},
           
          
		}
    },
    mounted(){
        this.index();
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
    },
	methods: {
         onResize () {
            
            if(window.innerWidth < 960){
                this.cancelBtnData.block = true;
                
            }
            else{
                this.cancelBtnData.block = false;
                
            }
        },
		
        index(){
			//this.$store.state.overlay = true
			this.loading = true;
            this.$store.state.loading = true;
			this.$api.commodity.get(this.id)
			.then((response) => {
                console.log(response);
                

                let suppliesText = '';
                response.data?.supplies?.split(",").forEach(br => {
                    suppliesText = suppliesText+`${br}\n`;
                });
                response.data.supplies_text = suppliesText;

                let suppliers = '';
                response.data?.suppliers?.forEach(br => {
                    suppliers = suppliers+`${br.name}\n`;
                });
                response.data.supplies_text = suppliers;
               
                this.items= [
                    { concepto: 'Nombre', index: 'prospect', valor:response.data.name},
                    { concepto: 'Tipo de materia', index: 'description', valor:suppliesText},
                    { concepto: 'Descripción', index: 'project_name', valor:response.data.description},
                    { concepto: 'Marca', index: 'validity', valor:response.data.brand},
                    { concepto: 'Unidad', index: 'foreign_exchange', valor: response.data?.unit?.name },
                    { concepto: 'Proveedores', index: 'header', valor:suppliers},
                  
                    
                ];

                //variants
                this.tableProps.items = response.data.variants.map((item) => {
                    return {...item, price: Utils.currencyFormat(item.price, "")}
                })

            
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                this.$store.state.loading = false;
			})
		},
       
    },
}
</script>

<style>
    .v-skeleton-loader__text{
        margin-bottom: 0px;
    }
    .pre{
        white-space: pre-line;
    }
</style> 